
import { Vue, Component, Prop, Model, Emit } from "vue-property-decorator";

@Component({})
export default class CreaSlider extends Vue {
  @Model("input", { type: Number }) Model = 0;

  @Prop({ type: String, default: "" }) readonly propertyName!: string;
  @Prop({ type: Boolean, default: false }) readonly readonly!: boolean;
  @Prop({ type: String, default: "" }) readonly label!: string;
  @Prop({ type: String, default: "" }) readonly prependIcon!: string;
  @Prop({ type: Number, default: 0 }) readonly min!: number;
  @Prop({ type: Number, default: 20 }) readonly max!: number;  
  @Prop({ type: undefined, default: undefined })
  readonly propertiesErrors!: Map<string, Array<string>> | undefined;
  @Prop({ type: Array, default: () => [] }) readonly rules!: Array<unknown>;

  @Emit("input")
  updateValue(value: number): number {
    return +value;
  }

  get propertyErrors(): Array<string> {
    if (
      this.propertiesErrors != undefined &&
      this.propertiesErrors.size > 0 &&
      this.propertiesErrors.get(this.propertyName) != undefined
    ) {
      const errors = this.propertiesErrors.get(this.propertyName);
      if (errors != undefined) {
        console.log(errors);
        return errors;
      }
      return [];
    }
    return [];
  }

  get isError(): boolean {
    const errors = this.propertiesErrors?.get(this.propertyName);
    if (errors != undefined) {
      return true;
    }
    return false;
  }
}
