
import { Component, Prop, Model, Vue } from "vue-property-decorator";

@Component({})
export default class CreaDataTable extends Vue {
  @Prop({ default: "" }) readonly title!: string;
  @Prop({ default: 10 }) readonly itemsPerPage!: number;
  @Prop({ type: Boolean, default: false }) readonly showSelect!: boolean;
  @Prop({ type: Boolean, default: false }) readonly loading!: boolean;
  @Prop({ type: Boolean, default: false }) readonly singleSelect!: boolean;
  @Prop({ type: Boolean, default: false }) readonly readonly!: boolean;

  @Prop({ type: Boolean, default: false }) readonly disablePagination!: boolean;
  @Prop({ type: Boolean, default: false }) readonly dense!: boolean;
  @Prop({ type: [String, Array], default: "" }) readonly sortBy!:
    | string
    | Array<string>;
  @Prop({ type: [Boolean, Array], default: false }) readonly sortDesc!:
    | boolean
    | Array<boolean>;

  @Prop({
    default: function () {
      return new Array<string>();
    },
  })
  readonly colProps!: Array<string>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({
    default: function () {
      return new Array<any>();
    },
  })
  readonly headers!: Array<any>;
  @Prop({
    default: function () {
      return new Array<unknown>();
    },
  })
  readonly data!: Array<unknown>;
  @Prop({ default: "Id" }) readonly itemKey!: string;

  @Model("input", { type: Array }) Model!: Array<unknown>;
  updateValue(value: Array<unknown>): void {
    this.$emit("input", value);
  }

  search = "";

  rowClicked(row: unknown): void {
    if (!this.readonly) {
      this.$emit("row-clicked", row);
    }
  }
}
