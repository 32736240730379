
import { Vue, Component, Prop, Model } from "vue-property-decorator";

@Component({})
export default class CreaSwitch extends Vue {
  @Model("change", { type: Boolean }) Model!: boolean;

  @Prop({ type: Boolean, default: false }) readonly readonly!: boolean;
  @Prop({ type: Boolean, default: false }) readonly inset!: boolean;
  @Prop({ type: String, default: "primary" }) readonly color!: string;
  @Prop({ type: String, default: "" }) readonly label!: string;
  @Prop({ type: String, default: "" }) readonly prependIcon!: string;
  @Prop() readonly messages!: string | Array<string>;

  updateValue(value: string | null): void {
    this.$emit("change", value);
  }
}
