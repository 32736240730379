
import {
  Filiera,
  HttpError,
} from "@/services/TransactionManager/TransactionManagerClient";
import { Component, Vue } from "vue-property-decorator";
import FilieraSelector from "./components/FilieraSelector.vue";
import { FiliereModule } from "./store";

@Component({
  components: {
    FilieraSelector,
  },
})
export default class ListFiliere extends Vue {
  error: { e: HttpError | undefined } = { e: undefined };
  dialog = false;

  closeDialog(): void {
    this.dialog = false;
    this.error.e = undefined;
  }

  async mounted(): Promise<void> {
    await this.loadData();
  }

  private async loadData(): Promise<void> {
    const err = await FiliereModule.RetrieveFiliere();
    if (err !== undefined) {
      this.error.e = err;
      this.dialog = true;
    }
  }

  get isError(): boolean {
    return this.error.e !== undefined;
  }

  get filiere(): Array<Filiera> {
    return FiliereModule.Filiere;
  }

  get errorDescription(): string {
    return this.error.e?.StatusCode.toString() ?? "";
  }

  selected(e: Filiera): void {
    FiliereModule.SET_FILIERA(e);
    this.$router.push({ name: "add-transazione" });
  }
}
