
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class CreaList extends Vue {
  @Prop({ type: String, default: "" }) readonly title!: string;
  @Prop({ type: String, default: "start" }) readonly justify!: string;
  readonly emptyListMessage!: string;
  @Prop({ type: Boolean, default: false }) readonly rounded!: boolean;
  @Prop({ type: String, default: "" }) readonly prependIcon!: string;
  @Prop({ type: String, default: "" }) readonly appendIcon!: string;

  get isDefaultSlotEmpty(): boolean {
    return !this.$slots.default;
  }
}
