
import { Vue, Component, Prop, Model, Emit, Watch } from "vue-property-decorator";

@Component({})
export default class CreaAutocomplete extends Vue {
  @Model("input") Model!: any | Array<any>;

  @Prop({ type: String, default: "" }) readonly model!: string;
  @Prop({ type: String, default: "" }) readonly propertyName!: string;
  @Prop({ type: String, default: "" }) readonly color!: string;
  @Prop({ type: Boolean, default: false }) readonly readonly!: boolean;
  @Prop({ type: String, default: "" }) readonly label!: string;
  @Prop({ type: String, default: "" }) readonly prependIcon!: string;
  @Prop({ type: Array, default: () => [] }) readonly rules!: Array<any>;
  @Prop({ type: undefined, default: undefined })
  readonly propertiesErrors!: Map<string, Array<string>> | undefined;

  @Prop({ type: Array, default: () => [] }) readonly items!: Array<any>;
  @Prop({ type: String, default: "Text" }) readonly itemText!: string;
  @Prop({ type: String, default: "Value" }) readonly itemValue!: string;
  @Prop({ type: Object, default: () => new Object() })
  readonly menuProps!: object;

  @Prop({ type: String, default: "" }) readonly secondaryProperty!: string;
  @Prop({ type: Boolean, default: false }) readonly multiple!: boolean;
  @Prop({ type: Boolean, default: false }) readonly returnObject!: boolean;

  @Emit("input")
  change(value: any): void {
    return value;
  }

  @Emit("search")
  search(value: string): string {
    return value;
  }

  searchInput = "";

  get propertyErrors(): string | Array<string> {
    if (
      this.propertiesErrors != undefined &&
      this.propertiesErrors.size > 0 &&
      this.propertiesErrors.get(this.propertyName) != undefined
    ) {
      const errors = this.propertiesErrors.get(this.propertyName);
      if (errors != undefined) {
        return errors;
      }
      return "";
    }
    return "";
  }

  get isError(): boolean {
    const errors = this.propertiesErrors?.get(this.propertyName);
    if (errors != undefined) {
      return true;
    }
    return false;
  }

  @Watch("searchInput", { deep: true })
  newSearch(newValue: string) {
    this.search(newValue);
  }
}
