
import { Component, Prop, Vue } from "vue-property-decorator";
import { Filiera } from "@/services/TransactionManager/TransactionManagerClient";

@Component({})
export default class FilieraSelector extends Vue {
  @Prop({ required: true, default: () => new Array<Filiera>() })
  readonly filiere!: Array<Filiera>;

  private numTransazioni(filiera: Filiera): number {
    return filiera.transazioni?.length ?? 0;
  }
}
