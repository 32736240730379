
import {
  Vue,
  Component,
  Prop,
  Model,
  Emit,
  Watch,
} from "vue-property-decorator";
import { format, parse } from "date-fns";

const DEFAULT_DATE = "";
const DEFAULT_TIME = "00:00";
//const DEFAULT_DATE_FORMAT = 'dd-MM-yyyy'
const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
const DEFAULT_TIME_FORMAT = "HH:mm:ss";
const DEFAULT_DIALOG_WIDTH = 340;
const DEFAULT_CLEAR_TEXT = "CANCELLA";
const DEFAULT_OK_TEXT = "OK";

@Component({})
export default class CreaDateTimePicker extends Vue {
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
  @Prop({ type: Boolean, default: false }) readonly loading!: boolean;
  @Prop({ type: String, default: "" }) readonly label!: string;
  @Prop({ type: String, default: DEFAULT_DATE_FORMAT }) dateFormat!: string;
  @Prop({ type: String, default: "HH:mm" }) timeFormat!: string;
  @Prop({ type: String, default: DEFAULT_CLEAR_TEXT }) clearText!: string;
  @Prop({ type: String, default: DEFAULT_OK_TEXT }) okText!: string;
  @Prop({ type: Number, default: DEFAULT_DIALOG_WIDTH })
  readonly dialogWidth!: number;
  @Prop({ type: Boolean, default: false }) readonly rounded!: boolean;
  @Prop({ type: Boolean, default: false }) readonly readonly!: boolean;
  @Prop({ type: Object }) textFieldProps!: object;
  @Prop({ type: Object }) datePickerProps!: object;
  @Prop({ type: Object }) timePickerProps!: object;

  $refs!: {
    // eslint-disable-next-line
    timer: any;
  };

  @Model("input", { type: [Date, String] }) readonly datetime!: Date | string;

  display = false;
  activeTab = 0;
  date = DEFAULT_DATE;
  time = DEFAULT_TIME;
  mounted() {
    this.init();
  }

  init() {
    if (!this.datetime) {
      return;
    }

    if (this.datetime instanceof Date) {
      let initDateTime = this.datetime;
      this.date = format(initDateTime, DEFAULT_DATE_FORMAT);
      this.time = format(initDateTime, DEFAULT_TIME_FORMAT);
    } else {
      let initDateTime = new Date(this.datetime);
      this.date = format(initDateTime, DEFAULT_DATE_FORMAT);
      this.time = format(initDateTime, DEFAULT_TIME_FORMAT);
    }
  }

  showTimePicker(): void {
    this.activeTab = 1;
  }

  resetPicker(): void {
    this.display = false;
    this.activeTab = 0;
    if (this.$refs.timer) {
      this.$refs.timer.selectingHour = true;
    }
  }

  get dateTimeFormat(): string {
    return this.dateFormat + " " + this.timeFormat;
  }

  get defaultDateTimeFormat(): string {
    return DEFAULT_DATE_FORMAT + " " + DEFAULT_TIME_FORMAT;
  }

  get formattedDatetime(): string {
    if (this.selectedDatetime) {
      let x = format(this.selectedDatetime, "dd-MM-yyyy HH:mm");
      return x;
    }
    return "";
  }

  get selectedDatetime(): number | Date | null {
    if (this.date && this.time) {
      let datetimeString = this.date + " " + this.time;
      if (this.time.length === 5) {
        datetimeString += ":00";
      }
      return parse(datetimeString, this.defaultDateTimeFormat, new Date());
    } else {
      return null;
    }
  }
  get dateSelected(): boolean {
    return !this.date;
  }

  @Emit("input")
  okHandler() {
    this.resetPicker();
    return this.selectedDatetime;
  }

  @Emit("input")
  clearHandler() {
    this.resetPicker();
    this.date = DEFAULT_DATE;
    this.time = DEFAULT_TIME;
    return null;
  }

  @Watch("datetime")
  onDatetimeChanged() {
    this.init();
  }
}
