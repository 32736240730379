import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store";
import {
  Filiera,
  HttpError,
  TransactionManagerClient,
} from "@/services/TransactionManager/TransactionManagerClient";
import { ConferimentoMateriaPrima } from "@/models/ConferimentoMateriaPrima";
import { isRight } from "@/utils/either";
import { Attore } from "@/models/Attore";
import { LottoMateriale, Materiale } from "@/models/LottoMateriale";

export interface FilieraState {
  Filiera: Filiera | undefined;
}

@Module({
  namespaced: true,
  dynamic: true,
  name: "filiere",
  store: store,
})
class FiliereStore extends VuexModule implements FilieraState {
  private filiera: { value: Filiera | undefined } = { value: undefined };
  private filiere: { value: Array<Filiera> } = {
    value: [
      {
        displayName: "Filiera hardcoded frontend",
        id: "79e2942f-bc7d-4fa1-ad50-300f3041b7d5",
        templateId: "template id",
        prodotto: { id: "id", nome: "prodotto name" },
        transazioni: [
          {
            da: { nome: "attore 1", id: "1" } as Attore,
            a: { nome: "attore 2", id: "2" } as Attore,
            lotto: {
              materiali: [{ nome: "materiale" } as Materiale],
            } as LottoMateriale,
            tipo: 1,
          } as ConferimentoMateriaPrima,
        ],
      },
    ],
  };
  private cli = new TransactionManagerClient(
    process.env.VUE_APP_TRANSACTIONMANAGER_BASEURL,
    undefined
  );

  @Mutation
  public SET_FILIERA(filiera: Filiera | undefined): void {
    this.filiera.value = filiera;
  }

  get Filiera(): Filiera | undefined {
    return this.filiera?.value;
  }

  @Mutation
  public SET_FILIERE(ff: Array<Filiera>): void {
    console.log("set filiere to:", ff);
    this.filiere.value = ff;
  }

  @Action({ rawError: true })
  public async RetrieveFiliere(): Promise<HttpError | undefined> {
    const r = await this.cli.ListFiliere();
    if (isRight(r)) {
      return r.value;
    }

    this.SET_FILIERE(r.value.filiere);
    return;
  }

  get Filiere(): Array<Filiera> {
    return this.filiere.value;
  }

  @Mutation
  public ADD_TRANSAZIONE(t: ConferimentoMateriaPrima): void {
    console.log("Mutation add transazione", t);
    this.filiera.value?.transazioni.push(t);
  }

  @Action({ rawError: true })
  public async AddTransazione(): Promise<void> {
    const tt = this.filiera.value?.transazioni;
    const id = this.filiera.value?.id;

    const validate: () => string | null = () => {
      if (!id) return "Filiera's Id is undefined";
      if (!tt || tt?.length == 0) return "Filiera has no Transazioni";

      const t = tt[tt.length - 1];
      if (t.tipo != 2)
        return `Only Transazioni with tipo Conferimento Materia Prima can be committed, reveived tipo = ${t.tipo}`;

      return null;
    };

    const error = validate();
    if (error) {
      console.log(`Error committing action 'AddTransazione': ${error}`);
      return;
    }

    // the if is for the linter
    if (!tt || !id) return;

    const t = tt[tt.length - 1];
    console.log("Committing 'AddTransazione' Conferimento Materia Prima", t);
    await this.cli.AddTransazione(id, t as ConferimentoMateriaPrima, tt.length);
  }
}

export const FiliereModule = getModule(FiliereStore, store);
