
import { Component, Vue, Prop, Model } from "vue-property-decorator";

@Component({ components: {} })
export default class CreaSummaryDialog extends Vue {
  @Model() Model!: boolean;

  @Prop({ type: String, default: "" }) readonly title!: string;

  onConfirm(): void {
    this.$emit("onConfirm");
  }

  onClose(): void {
    this.$emit("onClose");
  }
}
