import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import CreaTextField from "./components/CreaTextField.vue";
import CreaButton from "./components/CreaButton.vue";
import CreaSwitch from "./components/CreaSwitch.vue";
import CreaList from "./components/CreaList.vue";
import CreaListItem from "./components/CreaListItem.vue";
import CreaDatePicker from "./components/CreaDatePicker.vue";
import CreaDateTimePicker from "./components/CreaDateTimePicker.vue";
import CreaSummaryDialog from "./components/CreaSummaryDialog.vue";
import CreaTextArea from "./components/CreaTextArea.vue";
import CreaDropdown from "./components/CreaDropdown.vue";
import CreaSlider from "./components/CreaSlider.vue";
import CreaNumberField from "./components/CreaNumberField.vue";
import CreaAutocomplete from "./components/CreaAutocomplete.vue";
import CreaDataTable from "./components/CreaDataTable.vue";
import CreaAlert from "./components/CreaAlert.vue";

Vue.config.productionTip = false;

Vue.component("CreaTextField", CreaTextField);
Vue.component("CreaButton", CreaButton);
Vue.component("CreaSwitch", CreaSwitch);
Vue.component("CreaList", CreaList);
Vue.component("CreaListItem", CreaListItem);
Vue.component("CreaDatePicker", CreaDatePicker);
Vue.component("CreaDateTimePicker", CreaDateTimePicker);
Vue.component("CreaSummaryDialog", CreaSummaryDialog);
Vue.component("CreaTextArea", CreaTextArea);
Vue.component("CreaDropdown", CreaDropdown);
Vue.component("CreaSlider", CreaSlider);
Vue.component("CreaNumberField", CreaNumberField);
Vue.component("CreaAutocomplete", CreaAutocomplete);
Vue.component("CreaDataTable", CreaDataTable);
Vue.component("CreaAlert", CreaAlert);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
