export class Left<A> {
  tag = "left";
  value: A;

  constructor(a: A) {
    this.value = a;
  }
}

export class Right<B> {
  tag = "right";
  value: B;

  constructor(b: B) {
    this.value = b;
  }
}

export type Either<A, B> = Left<A> | Right<B>;

export function isLeft<A>(val: Either<A, unknown>): val is Left<A> {
  if ((val as Left<A>).tag == "left") return true;
  return false;
}

export function isRight<B>(val: Either<unknown, B>): val is Right<B> {
  if ((val as Right<B>).tag == "right") return true;
  return false;
}
