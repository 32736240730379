
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import SharedMixin from "@/mixin";
import { SharedModule } from "@/features/shared/store";

@Component({})
export default class CreaLoadingSpinner extends mixins(SharedMixin) {
  get isLoading(): boolean {
    return SharedModule.isLoading;
  }
}
