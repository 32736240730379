
import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component({})
export default class CreaListItem extends Vue {
  @Prop({ type: String, default: "" }) readonly prependIcon!: string;
  @Prop({ type: String, default: "" }) readonly appendIcon!: string;

  @Emit("click")
  onClick(): void {
    return;
  }
}
