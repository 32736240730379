
import SharedMixin from "@/mixin";
import router from "@/router";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";
import { VAppBar, VToolbarTitle, VSpacer } from "vuetify/lib";
import { OperationModule } from "../features/operations/store";
import CreaButton from "./CreaButton.vue";

@Component({})
export default class CreaNavBar extends mixins(SharedMixin) {
  get isLogged(): boolean {
    return OperationModule.isLogged;
  }
  toHome(): void {
    console.log("Tooperations");
    router.push({ name: "operations" }).catch((err) => {
      if (
        err.name !== "NavigationDuplicated" &&
        !err.message.includes(
          "Avoided redundant navigation to current location"
        )
      ) {
        // But print any other errors to the console
        console.log(err);
      }
    });
  }
}
