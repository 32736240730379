import { render, staticRenderFns } from "./CreaListItem.vue?vue&type=template&id=5cddf6d6&"
import script from "./CreaListItem.vue?vue&type=script&lang=ts&"
export * from "./CreaListItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports