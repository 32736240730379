import { TextValueDTO } from "@/models/TextValueDTO";
import Vue from "vue";
import Component from "vue-class-component";
import deepSearch from "@dionya_z/deep-search";

@Component
export default class SharedMixin extends Vue {
	public getTextByValue(array: Array<TextValueDTO<string>>, value: string) {
		return array.find((a) => a.Value == value)?.Text;
	}

	async redirectToOperations(): Promise<void> {
		const path = `${process.env.VUE_APP_FE_URL}/.auth/login/aad?post_login_redirect_uri=${process.env.VUE_APP_FE_URL}${process.env.VUE_APP_REDIRECT_URL_SUCCESS}`;
		window.location.replace(path);
	}

	public deepFilter<T>(value: string | number, obj: Array<T>, ...properties: Array<string>): Array<T> {
		const filteredList = deepSearch(
			value,
			obj,
			// Optional object to exclude some
			// props from the searching
			{
				props: [...properties],
				isInAllNesting: true,
			}
		);

		return filteredList;
	}
}
