import { VuexModule, Module, Mutation, getModule, Action, } from "vuex-module-decorators";
import store from "@/store";

export interface OperationState {
  isLogged: boolean;
  identityProvider: string;
  userId: string;
  userDetails: string;
  userRoles: Array<string>;
}

@Module({
  namespaced: true,
  dynamic: true,
  name: "operation",
  store: store,
})
class OperationStore extends VuexModule implements OperationState {
  identityProvider = "";
  userId = "";
  userDetails = "";
  userRoles = [];
  isLogged = false;

  @Action({ rawError: true })
  public async GetuserInformation(): Promise<any> {
    const response = await fetch('/.auth/me');
    const payload = await response.json();
    if (payload.clientPrincipal)
      this.SET_USER_INFORMATION(payload.clientPrincipal)
  }

  @Action({ rawError: true })
  public async GetToken(email: string): Promise<any> {
    const url = `https://login.microsoftonline.com/${process.env?.VUE_APP_AZURE_TENANT_ID}/oauth2/v2.0/authorize?client_id=${process.env?.VUE_APP_AZURE_CLIENT_ID}&response_type=code+id_token&redirect_uri=urn%3Aietf%3Awg%3Aoauth%3A2.0%3Aoob&response_mode=fragment&scope=user.read&state=12345`;
    const x = await fetch(url,{
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'no-cors', // no-cors, *cors, same-origin
      });
  }

  @Mutation
  public SET_USER_INFORMATION(payload: any): void {
    this.identityProvider = payload.identityProvider;
    this.userId = payload.userId;
    this.userDetails = payload.userDetails;
    this.userRoles = payload.userRoles;
    this.isLogged = true;
  }

  get CanAccessReport(): boolean {
		//return this.CanAccess(['admin', 'report']);
    const checker = (value: string | string[]) => ['admin', 'report'].some(element => value.includes(element));
		return this.userRoles.filter(checker).length > 0;
	}

  CanAccess(roles: Array<string> ): boolean {
    const checker = (value: string | string[]) => roles.some(element => value.includes(element));
		return this.userRoles.filter(checker).length > 0;
	}
}

export const OperationModule = getModule(OperationStore, store);
