
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ components: {} })
export default class CreaButton extends Vue {
  @Prop({ type: String, default: "primary" }) readonly color!: string;
  @Prop({ type: String, default: "" }) readonly text!: string;
  @Prop({ type: String, default: "" }) readonly icon!: string;
  @Prop({ type: Boolean, default: false }) readonly block!: boolean;

  click(item: unknown): void {
    this.$emit("click", item);
  }
}
