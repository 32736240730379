import { VuexModule, Module, Mutation, getModule, Action } from "vuex-module-decorators";
import store from "@/store";

@Module({ namespaced: true, dynamic: true, store, name: "SharedStoreModule" })
class SharedStore extends VuexModule {
	public _isLoading = false;
	@Mutation
	public SET_ISLOADING(isLoading: boolean): void {
		this._isLoading = isLoading;
	}
	get isLoading(): boolean {
		return this._isLoading;
	}

	public _errorMessages: Array<string> = new Array<string>();
	@Mutation
	public SET_ERROR_MESSAGES(messages: Array<string>): void {
		this._errorMessages = messages;
	}
	@Mutation
	public RESET_ERROR_MESSAGES(): void {
		this._errorMessages = new Array<string>();
	}
	get errorMessages(): Array<string> {
		return this._errorMessages;
	}

	public _errorUnhandledMessage = "";
	@Mutation
	public SET_ERROR_UNHANDLED_MESSAGE(message: string): void {
		this._errorUnhandledMessage = message;
	}
	get errorUnhandledMessage(): string {
		return this._errorUnhandledMessage;
	}

	@Action({ rawError: true })
	public async execRequests(requestsApi: any[]): Promise<boolean> {
		const errorMessages = new Array<string>();
		let allIsOk = true;
		SharedModule.SET_ISLOADING(true);
		try {
			for (const request of requestsApi) {
				await request();
			}
		} catch (err: any) {
			allIsOk = false;
			if (err.response.status === 500) {
				this.SET_ERROR_UNHANDLED_MESSAGE("Errore 500 non gestito");
			} else {
				console.log(err);
				errorMessages.push(...err.response?.data?.messages);
			}
		} finally {
			SharedModule.SET_ERROR_MESSAGES(errorMessages);
			SharedModule.SET_ISLOADING(false);
		}
		return allIsOk;
	}

	public FILIERA_TEMPLATE_ID = "D6854ED3-76B1-4112-8184-45FC9E514EE1";
	public MATERIALE_ARANCE_ID = "00000000-0000-0000-000b-000000000001";
	public PRODOTTO_ARANCE_ID = "00000000-0000-0000-0010-000000000001";
	public ORANFRIZER_ID = "00000000-0000-0000-000a-000000000001";
}

export const SharedModule = getModule(SharedStore, store);
