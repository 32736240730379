
import { Vue, Component, Prop, Model } from "vue-property-decorator";

@Component({})
export default class CreaTextField extends Vue {
  @Model("input", { type: String }) Model!: string;

  @Prop({ type: String, default: "" }) readonly propertyName!: string;
  @Prop({ type: Boolean, default: false }) readonly readonly!: boolean;
  @Prop({ type: String, default: "" }) readonly label!: string;
  @Prop({ type: String, default: "" }) readonly color!: string;
  @Prop({ type: String, default: "" }) readonly prependIcon!: string;
  @Prop({ type: String, default: "text" }) readonly type!: Array<unknown>;
  @Prop({ type: undefined, default: undefined })
  readonly propertiesErrors!: Map<string, Array<string>> | undefined;
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  readonly rules!: Array<unknown>;

  updateValue(value: string | null): void {
    this.$emit("input", value);
  }

  get propertyErrors(): Array<string> {
    if (
      this.propertiesErrors != undefined &&
      this.propertiesErrors.size > 0 &&
      this.propertiesErrors.get(this.propertyName) != undefined
    ) {
      const errors = this.propertiesErrors.get(this.propertyName);
      if (errors != undefined) {
        console.log(errors);
        return errors;
      }
      return [];
    }
    return [];
  }

  get isError(): boolean {
    const errors = this.propertiesErrors?.get(this.propertyName);
    if (errors != undefined) {
      return true;
    }
    return false;
  }
}
