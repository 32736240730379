
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";

import CreaButton from "./CreaButton.vue";

@Component({
  components: {
    CreaButton,
  },
})
export default class CreaCommonDialog extends Vue {
  @Prop({ type: String, default: "" }) readonly title!: string;
  @Prop({ type: String, default: "" }) readonly iconTitle!: string;

  @Prop({ type: String, default: "" }) readonly message!: string;
  @Prop({ type: Array, default: new Array<string>() })
  readonly messages!: Array<string>;
  @Prop({ type: String, default: "" }) readonly footerMessage!: string;
  @Prop({ type: String, default: "error" }) readonly color!: string;

  @Prop({ type: String, default: "mdi-close-circle" })
  readonly iconButton!: string;
  @Prop({ type: Boolean, default: false }) readonly onlyAgree!: boolean;

  get showDialog(): boolean {
    return !!this.message || this.messages.length > 0;
  }

  get darkenColor(): string {
    return `${this.color} darken-1`;
  }

  get lightenColor(): string {
    return `${this.color} lighten-5`;
  }

  get classCard(): string {
    return `headline ${this.color} darken-1`;
  }

  @Emit("agree")
  agree(): void {
    return;
  }

  @Emit("reject")
  reject(): void {
    return;
  }
}
