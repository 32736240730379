import { Attore } from "@/models/Attore";
import { ConferimentoMateriaPrima } from "@/models/ConferimentoMateriaPrima";
import { LottoMateriale } from "@/models/LottoMateriale";
import { Transazione } from "@/models/TransazioneBase";
import { Either, Left, Right } from "@/utils/either";
import { Axios, AxiosRequestConfig, AxiosResponse } from "axios";

export interface Filiera {
  displayName: string;
  id: string;
  templateId: string;
  prodotto: {
    id: string;
    nome: string;
  };
  transazioni: Array<Transazione>;
}

export interface ITransactionManagerClient {
  ReadFiliera(id: string): Promise<Either<Filiera, HttpError>>;
  AddTransazione(
    id: string,
    transazione: ConferimentoMateriaPrima,
    pos: number
  ): Promise<HttpError | undefined>;
}

export interface HttpError {
  Message: string;
  StatusCode: number;
}

export interface ListFiliereResponse {
  filiere: Array<Filiera>;
}

export class TransactionManagerClient implements ITransactionManagerClient {
  private client: Axios;

  public constructor(
    baseUrl: string | undefined,
    requestInterceptor: TransactionManagerClientRequestInterceptor | undefined
  ) {
    this.client = new Axios({
      baseURL: baseUrl,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (requestInterceptor !== undefined)
      this.client.interceptors.request.use(requestInterceptor);
  }

  public async ReadFiliera(id: string): Promise<Either<Filiera, HttpError>> {
    const r = await this.client.get(`/filiere/${id}`);
    const resp = this.mapResponse<Filiera>(r);
    return resp;
  }

  public async ListFiliere(): Promise<Either<ListFiliereResponse, HttpError>> {
    const r = await this.client.get<ListFiliereResponse>(`/filiere`);
    const resp = this.mapResponse<ListFiliereResponse>(r);
    console.log("resp", resp);
    return resp;
  }

  public async AddTransazione(
    id: string,
    transazione: ConferimentoMateriaPrima,
    pos: number
  ): Promise<HttpError | undefined> {
    console.log("posting transazione", transazione);
    const cmd: AddTransazioneConferimentoMateriaPrimaCommand = {
      idFiliera: id,
      da: transazione.da,
      a: transazione.a,
      lotto: transazione.lotto,
      position: pos,
    };

    const r = await this.client.post(
      `/filiere/${id}/transazioni/conferimentomateriaprima/`,
      JSON.stringify(cmd)
    );

    if (200 <= r.status && r.status < 300) {
      return undefined;
    }

    return { Message: r.data, StatusCode: r.status };
  }

  private mapResponse<T>(r: AxiosResponse): Either<T, HttpError> {
    if (200 <= r.status && r.status < 300) {
      const t: T = JSON.parse(r.data);
      return new Left<T>(t);
    }

    return new Right<HttpError>({ Message: r.data, StatusCode: r.status });
  }
}

interface AddTransazioneConferimentoMateriaPrimaCommand {
  idFiliera: string;
  da: Attore;
  a: Attore;
  lotto: LottoMateriale;
  position: number;
}

type TransactionManagerClientRequestInterceptor = <D = unknown>(
  c: AxiosRequestConfig<D>
) => AxiosRequestConfig<D>;
