
import { Vue, Component, Prop, Model } from "vue-property-decorator";

@Component({})
export default class CreaTextArea extends Vue {
  @Model("input", { type: String }) Model!: string;

  @Prop({ type: String, default: "" }) readonly model!: string;
  @Prop({ type: Number, default: 5 }) readonly rows!: number;
  @Prop({ type: String, default: "" }) readonly propertyName!: string;
  @Prop({ type: Boolean, default: false }) readonly readonly!: boolean;
  @Prop({ type: String, default: "" }) readonly prependIcon!: string;
  @Prop({ type: String, default: "" }) readonly label!: string;
  @Prop({ type: String, default: "" }) readonly color!: string;
  @Prop({ type: Array, default: () => (new Array<any>()) }) readonly rules!: Array<any>;
  @Prop({ type: undefined, default: undefined })
  readonly propertiesErrors!: Map<string, Array<string>> | undefined;

  updateValue(value: string | null): void {
    this.$emit("input", value);
  }

  get propertyErrors(): Array<string> {
    if (
      this.propertiesErrors === undefined ||
      this.propertiesErrors.size === 0 ||
      this.propertiesErrors.get(this.propertyName) === undefined
    ) {
      return [];
    }

    const errors = this.propertiesErrors.get(this.propertyName);
    if (errors === undefined) {
      return [];
    }

    console.log(errors);
    return errors;
  }

  get isError(): boolean {
    const errors = this.propertiesErrors?.get(this.propertyName);
    if (errors != undefined) {
      return true;
    }
    return false;
  }
}
