
import { Component, Vue } from "vue-property-decorator";
import ListFiliere from "@/features/filiere/ListFiliere.vue";

@Component({
  components: {
    ListFiliere,
  },
})
export default class HomeView extends Vue {}
