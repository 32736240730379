
import CreaButton from "@/components/CreaButton.vue";
import SharedMixin from "@/mixin";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";
import { VContent, VParallax, VLayout } from "vuetify/lib";

@Component({})
export default class CreaLanding extends mixins(SharedMixin) {
  get mainImageLink(): string {
    return `${process.env.BASE_URL}img/splashscreen.png`;
  }
}
