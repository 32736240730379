
  import { Component, Vue } from "vue-property-decorator";
  import CreaLoginForm from "./components/CreaLanding.vue";

  @Component({
    components: {
      CreaLoginForm,
    },
  })
  export default class CreaHomePageIndex extends Vue {
   
  }
