import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import CreaHomePageIndex from "@/features/home/CreaHomePageIndex.vue";
import { OperationModule } from "@/features/operations/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: CreaHomePageIndex,
  },
  {
    path: "/login",
    name: "login",
    component: CreaHomePageIndex,
    beforeEnter(to, from, next) {
      const path = `${process.env.VUE_APP_FE_URL}/.auth/login/aad?post_login_redirect_uri=${process.env.VUE_APP_FE_URL}${process.env.VUE_APP_REDIRECT_URL_SUCCESS}`;
      window.location.href = path;
    }
  },
  {
    path: "/operations",
    name: "operations",
    component: () =>
      import("@/features/operations/CreaOperationsPageIndex.vue"),
  },
  {
    path: "/ordini-raccolta",
    name: "ordini-raccolta",
    component: () =>
      import("@/features/ordini-raccolta/CreaOrdiniRaccoltaPageIndex.vue"),
  },
  {
    path: "/ordini-raccolta/:id/details",
    name: "ordini-raccolta-details",
    component: () =>
      import("@/features/ordini-raccolta/CreaOrdiniRaccoltaPageDetails.vue"),
  },
  {
    path: "/ordini-raccolta/create",
    name: "ordini-raccolta-create",
    component: () =>
      import("@/features/ordini-raccolta/CreaOrdiniRaccoltaPageCreate.vue"),
  },
  {
    path: "/quality-check",
    name: "quality-check",
    component: () =>
      import("@/features/quality-check/CreaQualityCheckPageIndex.vue"),
  },
  {
    path: "/quality-check/:id/details",
    name: "quality-check-details",
    component: () =>
      import("@/features/quality-check/CreaQualityCheckPageDetails.vue"),
  },
  {
    path: "/quality-check/create",
    name: "quality-check-create",
    component: () =>
      import("@/features/quality-check/CreaQualityCheckPageCreate.vue"),
  },
  {
    path: "/processing",
    name: "processing",
    component: () =>
      import("@/features/processing/CreaProcessingPageCreate.vue"),
  },
  {
    path: "/final-product-qc",
    name: "final-product-qc",
    component: () =>
      import("@/features/final-product-quality-check/CreaFinalProductQualityCheckPageIndex.vue"),
  },
  {
    path: "/final-product-qc/:id/create",
    name: "final-product-qc-create",
    component: () =>
      import("@/features/final-product-quality-check/CreaFinalProductQualityCheckPageCreate.vue"),
  },
  {
    path: "/final-product-qc/:id/details",
    name: "final-product-qc-details",
    component: () =>
      import("@/features/final-product-quality-check/CreaFinalProductQualityCheckPageDetails.vue"),
  },
  {
    path: "/data-export",
    name: "data-export",
    component: () =>
      import("@/features/data-export/CreaDataExportPageIndex.vue"),
  },
  {
    path: "/addtransazione",
    name: "add-transazione",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});
router.beforeEach(async (to, from, next) => {
  await OperationModule.GetuserInformation();
  if (to.name !== "login" && to.name !== "home" && !OperationModule.isLogged)
    next({ name: 'login' })
  // if the user is not authenticated, `next` is called twice
  next()
})
export default router;
