<template>
  <v-app>
    <crea-nav-bar />
    <crea-loading-spinner />
    <crea-common-dialog
    title="Errori"
    icon-title="mdi-list-status"
    :messages="errorMessages"
    icon-button="mdi-check"
    color="deep-orange"
    only-agree
    @agree="resetErrorMessages"
    />
    <v-main>
      <v-container class="scrollable-content">
        <router-view>
          <home-view />
        </router-view>
      </v-container>
    </v-main>
    <crea-alert
      :text="errorUnhandled"
      @onClose="resetErrorUnhandledMessage"
    />
  </v-app>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import HomeView from "@/views/HomeView";
import CreaNavBar from "@/components/CreaNavBar";
import CreaLoadingSpinner from "@/components/CreaLoadingSpinner.vue";
import CreaCommonDialog from "@/components/CreaCommonDialog.vue";
import { SharedModule } from "@/features/shared/store";

@Component({
  components: { HomeView, CreaNavBar, CreaLoadingSpinner, CreaCommonDialog },
})
export default class App extends Vue {
  get errorMessages() {
    return SharedModule.errorMessages;
  }
  resetErrorMessages() {
    SharedModule.RESET_ERROR_MESSAGES();
  }

  get errorUnhandled() {
    return SharedModule.errorUnhandledMessage;
  }
  resetErrorUnhandledMessage() {
    SharedModule.SET_ERROR_UNHANDLED_MESSAGE("");
  }
}
</script>
