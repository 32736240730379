
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ components: {} })
export default class CreaButton extends Vue {
  @Prop({ type: String, default: "error" }) readonly color!: string;
  @Prop({ type: String, default: "error" }) readonly type!: string;
  @Prop({ type: String, default: "" }) readonly text!: string;

  get showAlert(): boolean {
    return !!this.text;
  }

  closeAlert() {
    this.$emit("onClose");
  }
}
